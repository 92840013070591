import { hubspotActionsType } from '../../constants';
import { HubspotService } from "../../services/companies/hubspot.service";

const getOwners = () => {
    return async dispatch => {
        try {
            const { results } = await HubspotService.get('hubspot/owners');
            dispatch({ type: hubspotActionsType.LIST_OWNERS, owners: results });
        } catch (error) {
            console.error('Error fetching owners:', error);
        }
    };
};

export const hubspotActions = {
    getOwners
};