import React, { useEffect, useState } from "react";
import { Typography, CircularProgress, Button, Card, IconButton, CardActions, Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, DialogActions, FormControl, OutlinedInput, InputLabel, ListItemText, Checkbox, DialogContentText } from "@mui/material";
import { TeamService } from "../services/teams/team.service";
import { UserService } from "../services/users";
import TeamList from "../components/team/TeamList";
import UserList from "../components/team/UserList";
import { profiles } from "../helpers/utils";
import { CURRENT_USER } from "../constants";
import { jwtDecode } from "jwt-decode";
import { Group, Person } from "@mui/icons-material";

export default function TeamManagementPage() {
    //const [user] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showTeams, setShowTeams] = useState(true);
    const [openTeamDialog, setOpenTeamDialog] = useState(false);
    const [newTeamName, setNewTeamName] = useState('');
    const [newTeamLeader, setNewTeamLeader] = useState(undefined);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [editingTeam, setEditingTeam] = useState(null);
    const [teamNameError, setTeamNameError] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);
    const [loadSaveTeam, setLoadSaveTeam] = useState(false);

    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [userTeam, setUserTeam] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [loadSaveUser, setLoadSaveUser] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(false);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [currentUser, setCurrentUser] = useState(false);
    const [loadingUser, setLoadingUser] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('TOKEN_USER');
        if (token) {
            const user = jwtDecode(token); // Décodez le token
            setCurrentUser(user);
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            fetchTeams();
            fetchUsers();
        }
    }, [currentUser]);

    const fetchTeams = async () => {
        try {
            const fetchedTeams = await TeamService.getList();
            setTeams(fetchedTeams);
            console.log("currentUser", currentUser);
            const teamsFiltered = currentUser.profile == 'MANAGER' ? fetchedTeams.filter(team => {
                console.log("currentUser.id", currentUser.id);
                console.log("team.leadId", team.leadId);
                return team.leadId === currentUser.id;
            }
            ) : fetchedTeams;

            setFilteredTeams(teamsFiltered);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const fetchUsers = async () => {
        try {
            const fetchedUsers = await UserService.getUsers();
            setUsers(fetchedUsers);
            const usersFiltered = currentUser.profile == 'MANAGER' ? fetchedUsers.filter(user => user.teamId == currentUser.teamId) : fetchedUsers;
            console.log('usersFiltered', usersFiltered);
            setFilteredUsers(usersFiltered);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleEditTeam = (team) => {
        setNewTeamName(team.name);
        setEditingTeam(team);
        setNewTeamLeader(team.leadId);
        const memberIds = team.members
        .filter(member => member.id !== team.leadId)
        .map(member => member.id);
        setSelectedUsers(memberIds);
        setOpenTeamDialog(true);
    };

    const toggleView = () => {
        const usersFiltered = currentUser.profile == 'MANAGER' ? users.filter(user => user.teamId == currentUser.teamId) : users;
        setFilteredUsers(usersFiltered);
        setShowTeams(!showTeams);
    };

    const handleOpenTeamDialog = () => {
        setNewTeamName('');
        setNewTeamLeader('');
        setSelectedUsers([]);
        setEditingTeam(null);
        setOpenTeamDialog(true);
    }

    const handleCloseTeamDialog = () => {
        setNewTeamName('');
        setNewTeamLeader('');
        setEditingTeam('');
        setSelectedUsers([]);
        setOpenTeamDialog(false)
    }

    const handleTeamNameChange = (e) => {
        setNewTeamName(e.target.value);
        if (e.target.value.trim() !== '') {
            setTeamNameError(false);
        }
    };

    const handleDeleteTeam = (team) => {
        setTeamToDelete(team);
        setOpenDeleteDialog(true);
    };

    const handleConfirmDeleteTeam = async (team) => {
        await TeamService.deleteTeam(team.id);
        await Promise.all([fetchTeams(), fetchUsers()]);
        setOpenDeleteDialog(false);

    }

    const handleSave = () => {
        if (newTeamName.trim() === '') {
            setTeamNameError(true);
            return;
        }
        setTeamNameError(false);
        handleSaveTeam();
    }

    const handleSaveTeam = async () => {
        setLoadSaveTeam(true);
        const teamData = {
            name: newTeamName,
            leadId: newTeamLeader,
        };
        let membersData = [...selectedUsers];
        if (newTeamLeader && !membersData.includes(newTeamLeader)) {
            membersData.unshift(newTeamLeader);
        }
        let teamId;
        if (editingTeam) {
            const data = {
                userIds: editingTeam.members.map(user => user.id),
                teamId: null
            }
            const updateUsersPromise = UserService.updateUsersTeam(data);
            const updateTeamPromise = TeamService.updateTeam(editingTeam.id, teamData);
            await Promise.all([updateUsersPromise, updateTeamPromise]);
            teamId = editingTeam.id;
            setNewTeamName('');
            setNewTeamLeader('');
            setEditingTeam('');
        } else {
            const createdTeam = await TeamService.createTeam(teamData);
            teamId = createdTeam.id;
        }
        if (membersData.length > 0 && teamId) {
            const data = {
                userIds: membersData,
                teamId: +teamId
            }
            await UserService.updateUsersTeam(data);
        }
        await Promise.all([fetchTeams(), fetchUsers()]);
        setLoadSaveTeam(false);
        setOpenTeamDialog(false);
    }

    const handleEditUser = (user) => {
        setUserName(user?.firstName + ' ' + user?.lastName);
        setUserEmail(user.email);
        setUserProfile(user.profile);
        setUserTeam(user.teamId);
        setEditingUser(user);
        setOpenUserDialog(true)
    };

    const handleUsersChange = (event) => {
        const { target: { value } } = event;
        setSelectedUsers(typeof value === 'string' ? value.split(',') : value);
    };

    const handleDeleteUser = (userId) => {
        console.log(`Delete user with ID: ${userId}`);
    };
    const handleSaveUser = async () => {
        setLoadSaveUser(true);
        if (editingUser) {
            const userId = editingUser.id;
            const userData = {
                profile: userProfile,
                teamId: userTeam ? userTeam : null
            };
            await UserService.update(userId, userData);
        }
        await Promise.all([fetchTeams(), fetchUsers()]);
        setLoadSaveUser(false);
        setOpenUserDialog(false)
    }

    const handleCloseUserDialog = () => {
        setOpenUserDialog(false)
    }

    const handleTeamClick = (team) => {
        const teamUsers = users.filter(user => user.teamId === team.id);
        setFilteredUsers(teamUsers);
        setShowTeams(false);
    };

    return (
        <div style={{ padding: "10px" }}>
            <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px' }}>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        textTransform: "uppercase",
                        flexGrow: 1,
                        textAlign: 'center',
                        marginLeft: 7,
                        fontWeight: 800
                    }}
                >
                    {showTeams ? "Liste des équipes" : "Liste des utilisateurs"}
                </Typography>
                <CardActions>
                    <IconButton onClick={toggleView} color="success">
                        {showTeams ? <Person sx={{ fontSize: 40 }} /> : <Group sx={{ fontSize: 40 }} />}
                    </IconButton>
                </CardActions>
            </Card>

            {loading ? (
                <></>
                // <CircularProgress />
            ) : (
                <>
                    {currentUser.profile === 'SUPERADMIN' && (
                        <div>
                            {showTeams ? (
                                <TeamList
                                    teams={filteredTeams}
                                    onEditTeam={handleEditTeam}
                                    onDeleteTeam={handleDeleteTeam}
                                    onCreateTeam={handleOpenTeamDialog}
                                    onTeamClick={handleTeamClick}
                                    currentUser={currentUser}
                                />
                            ) : (
                                <UserList users={filteredUsers} onEditUser={handleEditUser} onDeleteUser={handleDeleteUser} />
                            )}
                        </div>
                    )}

                    {currentUser.profile === 'MANAGER' && (
                        <div>
                            {showTeams ? (
                                <TeamList
                                    teams={filteredTeams}
                                    onEditTeam={handleEditTeam}
                                    onDeleteTeam={handleDeleteTeam}
                                    onCreateTeam={handleOpenTeamDialog}
                                    onTeamClick={handleTeamClick}
                                    currentUser={currentUser}
                                />
                            ) : (
                                <UserList users={filteredUsers} onEditUser={handleEditUser} onDeleteUser={handleDeleteUser} />
                            )}
                        </div>
                    )}
                    {currentUser.profile === 'COMMERCIAL' && (
                        <p>Vous n'avez pas la permission de voir cette section.</p>
                    )}

                    {/* Dialog pour team */}
                    <Dialog
                        open={openTeamDialog}
                        onClose={handleCloseTeamDialog}
                        sx={{
                            '& .MuiDialog-paper': {
                                width: '400px',
                                height: 'auto',
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                padding: '16px'
                            }}
                        >
                            {editingTeam ? 'Mettre à jour l\'équipe' : 'Créer une équipe'}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Nom de l'équipe"
                                fullWidth
                                value={newTeamName}
                                onChange={handleTeamNameChange}
                                required
                                error={teamNameError}
                                helperText={teamNameError ? "Le nom de l'équipe est requis" : ""}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Chef d'équipe</InputLabel>
                                <Select
                                    value={newTeamLeader || ''}
                                    onChange={(e) => setNewTeamLeader(e.target.value)}
                                    input={<OutlinedInput label="Chef d'équipe" />}
                                >
                                    <MenuItem value={undefined}>
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {users
                                        .filter(user => user.teamId === null || user.teamId === editingTeam?.id)
                                        .filter((user) => !selectedUsers.includes(user.id))
                                        .map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.firstName} {user.lastName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Membres d'équipe</InputLabel>
                                <Select
                                    multiple
                                    value={selectedUsers || ''}
                                    onChange={handleUsersChange}
                                    input={<OutlinedInput label="Membres d'équipe" />}
                                    renderValue={(selected) => selected.map(id => {
                                        const user = users.find(user => user.id === id);
                                        return `${user.firstName} ${user.lastName}`;
                                    }).join(', ')}
                                >
                                    {users
                                        .filter(user => user.teamId === null || user.teamId === editingTeam?.id)
                                        .filter((user) => user.id !== newTeamLeader)
                                        .map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                <Checkbox checked={selectedUsers.indexOf(user.id) > -1} />
                                                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '16px'
                            }}>
                            <Button
                                onClick={handleCloseTeamDialog}
                                color="warning" variant="outlined" style={{ width: 150 }}
                            >
                                Annuler
                            </Button>
                            <Button
                                onClick={handleSave}
                                disabled={teamNameError}
                                color="success" variant="outlined" style={{ width: 150 }}
                            >
                                {!loadSaveTeam ? "Sauvegarder" : <CircularProgress size={20} color="inherit" />}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth="xs"
                        open={openDeleteDialog}
                        onClose={() => setOpenDeleteDialog(false)}
                        fullWidth
                        PaperProps={{ style: { borderRadius: 10, padding: '15px 0' } }}
                    >
                        <DialogTitle style={{ padding: 0, textAlign: 'center' }}>Confirmation</DialogTitle>
                        <DialogContent style={{ padding: 0, textAlign: 'center' }}>
                            <DialogContentText style={{ padding: 0, textAlign: 'center' }}>
                                <p>Êtes-vous sûr de vouloir supprimer l'équipe : <strong>{teamToDelete?.name}</strong>?<br />Cette action est irreversible.</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ padding: 0, justifyContent: 'center' }}>
                            <Button onClick={() => setOpenDeleteDialog(false)} color="error" variant="outlined" style={{ width: 150 }}>
                                Non
                            </Button>
                            <Button
                                variant="outlined"
                                color="success"
                                fullWidth
                                onClick={() => handleConfirmDeleteTeam(teamToDelete)}
                                sx={{ width: 150 }}
                                startIcon={loading && <CircularProgress size={20} color="inherit" />}
                            >
                                {!loading && <span>Oui</span>}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Dialog pour user */}
                    <Dialog
                        open={openUserDialog}
                        onClose={handleCloseUserDialog}
                        sx={{
                            '& .MuiDialog-paper': {
                                width: '400px',
                                height: 'auto',
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                padding: '16px'
                            }}
                        >
                            Mettre à jour l'utilisateur
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Nom"
                                fullWidth
                                value={userName}
                                required
                                error={teamNameError}
                                disabled
                            />
                            {/* <TextField
                                autoFocus
                                margin="dense"
                                label="Email"
                                fullWidth
                                value={userEmail}
                                required
                                error={teamNameError}
                                disabled 
                            /> */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Profil</InputLabel>
                                <Select
                                    value={userProfile || ''}
                                    onChange={(e) => setUserProfile(e.target.value)}
                                    input={<OutlinedInput label="Profil" />}
                                >
                                    {profiles.map((profile) => (
                                        <MenuItem key={profile.id} value={profile.value}>
                                            {profile.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Équipe</InputLabel>
                                <Select
                                    value={userTeam || ''}
                                    onChange={(e) => setUserTeam(e.target.value)}
                                    input={<OutlinedInput label="Équipe" />}
                                >
                                    <MenuItem value={undefined}>
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {teams?.map((team) => (
                                        <MenuItem key={team.id} value={team.id}>
                                            {team.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '16px'
                            }}
                        >
                            <Button
                                onClick={handleCloseUserDialog}
                                color="warning" variant="outlined" style={{ width: 150 }}
                            >
                                Annuler
                            </Button>
                            <Button
                                onClick={handleSaveUser}
                                color="success" variant="outlined" style={{ width: 150 }}
                            >
                                {!loadSaveUser ? "Enregistrer" : <CircularProgress size={20} color="inherit" />}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </>
            )}
        </div>
    );
}
