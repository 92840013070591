import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { loading } from './loader.reducer';
import { authentication } from './auth.reducer';
import { hubspot } from './hubspot.reducer';

const RootReducer = combineReducers({
    alert,
    authentication,
    loading,
    hubspot
});

export default RootReducer;
