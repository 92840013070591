import { commonService } from '../generic.service';
//
const getList = (startDate) => {
    return commonService.get('Companies/', {params : {startDate: ""}});
}

const updateLifecyclePhase = (id, lifecyclestage, lastLifecyclestage) => {
    const response = commonService.put(`Companies/${id}/update`, { lifecyclestage, lastLifecyclestage });
    return response;
};

const updateOwner = (id, hubspot_owner_id) => {
    return commonService.put(`Companies/${id}/update`, { hubspot_owner_id });
};

const updateNotes = (id, notes_sur_l_inscription) => {
    return commonService.put(`Companies/${id}/update`, { notes_sur_l_inscription });
};

const updateNombreVisite = (id, nombre_de_visite, date_des_visites) => {
    return commonService.put(`Companies/${id}/update`, { nombre_de_visite: parseInt(nombre_de_visite), date_des_visites });
};

const updateLastVisiteTimestamp = (id, lastvisit) => {
    return commonService.put(`Companies/${id}/update`, { lastvisit });
};

const createCompany = (data) => {
    return commonService.post(`Companies`, data);
};

const deleteCompany = (id) => {
    return commonService.remove(`Companies/${id}`);
};

const updateConversionDate = (idForUpdate, columnName, date) => {
    return commonService.put(`Companies/${idForUpdate}/update`, { columnName, date });
};

const insertHistory = (data) => {
    return commonService.post(`Companies/history`, data);
}

export const CompanyService = {
    getList,
    createCompany,
    updateLifecyclePhase,
    deleteCompany,
    updateNotes,
    updateNombreVisite,
    updateOwner,
    updateLastVisiteTimestamp,
    updateConversionDate,
    insertHistory
};
 