import { commonService } from '../generic.service';
//
const getContactsList = (companyId) => {
    return commonService.get(`hubspot/contacts?companyId=${companyId}`);
};

const updateContact = (id, data) => {
    return commonService.patch(`hubspot/contacts/${id}/update`, { data });
};

const createContact = (data) => {
    return commonService.post(`hubspot/contacts`, data);
};

const deleteContact = (id) => {
    return commonService.remove(`hubspot/contacts/${id}`);
};

const fetchCompaniesAssociedContactId = (contactId) => {
    return commonService.get(`hubspot/CompaniesAssociedContact?contactId=${contactId}`);
}

const disassociateContactCompanies = (contactId, companyIds) => {
    return commonService.post(`hubspot/disassociateCompanies`, {
        contactId,
        companyIds,
    });
};


export const ContactService = {
    getContactsList,
    updateContact,
    createContact,
    deleteContact,
    fetchCompaniesAssociedContactId,
    disassociateContactCompanies
};
 