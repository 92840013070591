import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement);

const ModelLine = () => {
    const data = {
        labels: ['Jan', 'F\u00E9v', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Ao\u00FBt', 'Sep', 'Oct', 'Nov', 'D\u00E9c'],
        datasets: [
            {
                label: 'Accords Obtenus',
                data: [5, 10, 8, 15, 12, 20, 25, 22, 18, 30, 28, 35],
                borderColor: '#3f51b5',
                yAxisID: 'y',
            },
            {
                label: 'Primes (€)',
                data: [500, 1000, 800, 1500, 1200, 2000, 2500, 2200, 1800, 3000, 2800, 3500],
                borderColor: '#f50057',
                yAxisID: 'y1',
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                type: 'linear',
                position: 'left',
                ticks: {
                    callback: (value) => `${value}`,
                },
            },
            y1: {
                type: 'linear',
                position: 'right',
                ticks: {
                    callback: (value) => `€${value}`,
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    return (
        <>
            <Line data={data} options={options} />
        </>
    )
}

export default ModelLine;