import React from "react";

export default function ComingSoon() {
    return (
        <div style={styles.container}>
            <h1 style={styles.text}>COMING SOON</h1>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "calc(100vh - 100px)",
        paddingTop: "4rem",
    },
    text: {
        fontSize: "2rem",
        color: "#333",
    },
};