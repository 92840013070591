import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchAndStoreCompanies as fetchAndStoreLocalyCompanies } from './dataaccess/db';
import { jwt } from './helpers/jwt';
import MainRoutes from './components/MainRoutes';
import { authActionsType, SETTINGS } from './constants';
import { AuthProvider } from './authContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { frFR } from '@mui/x-date-pickers/locales';
import "./styles/main.scss";
import 'dayjs/locale/fr';
import packageJson from '../package.json';
import { alertActions } from './redux/actions';


const { REACT_APP_REFRESH_INTERVAL } = process.env;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedPreferences = localStorage.getItem(SETTINGS);
    return savedPreferences ? JSON.parse(savedPreferences).darkMode : false;
  });
  const [open, setOpen] = useState(false);
  const { alert, loading } = useSelector(state => state);
  const isTokenExpired = jwt.isExpired();
  const dispatch = useDispatch();

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    setOpen(alert.message != null);
  }, [alert]);

  useEffect(() => {
    const checkVersion = () => {
      const currentVersion = packageJson.version;
      const storedVersion = localStorage.getItem('appVersion');
      if (storedVersion && storedVersion !== currentVersion) {
        dispatch(alertActions.error(`Une nouvelle version ${currentVersion} est disponible. Veuillez vous reconnecter.`));
        dispatch({ type: authActionsType.LOGOUT });
        localStorage.clear();

        localStorage.setItem('appVersion', currentVersion);
      } else {
        localStorage.setItem('appVersion', currentVersion);
      }
    };

    const handleUserInteraction = () => {
      checkVersion();
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('mousemove', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('mousemove', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isTokenExpired)
        await fetchAndStoreLocalyCompanies();
    };

    fetchData();

    const intervalId = setInterval(fetchData, REACT_APP_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [isTokenExpired]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    const updatedPreferences = {
      darkMode: newDarkMode,
    };
    localStorage.setItem(SETTINGS, JSON.stringify(updatedPreferences));
  };

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ margin: '10px' }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.type}
            sx={{ width: '100%', fontSize: '1rem' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Router>
          <LocalizationProvider dateAdapter={AdapterDayjs}
            adapterLocale="fr"
            localeText={{
              ...frFR.components.MuiLocalizationProvider.defaultProps.localeText,
              cancelButtonLabel: 'Annuler',  // Traduction du bouton Annuler
              okButtonLabel: 'OK',           // Traduction du bouton OK
            }}>
            <MainRoutes
              darkMode={darkMode} toggleDarkMode={toggleDarkMode}
            />
          </LocalizationProvider>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
