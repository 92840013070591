import { commonService } from '../generic.service';
const register = (email) => {
    return commonService.post(`users`, { email });
};

const initPassword = (token, password) => {
    return commonService.post(`users/init-password`, { token, password });
};

const resetPassword = (email) => {
    return commonService.post(`users/reset-password`, { email });
};

const getUsers = () => {
    return commonService.get(`users`);
};

const updateUsersTeam = (data) => {
    return commonService.put(`users/updateUsersTeam`, data );
};

const update = (id, data) => {
    return commonService.put(`users/${id}/update`, data );
};

export const UserService = {
    register,
    initPassword,
    resetPassword,
    getUsers,
    updateUsersTeam,
    update
};
