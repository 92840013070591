import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement } from 'chart.js';
import { getStatColor, shortenName, lifecyclePhases } from '../../helpers/utils';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement);

const ModelBar = ({ data, teams, lifecylephase }) => {

    const getBackgroundColor = (lifecyclePhase) => {
        console.log('date_de_conversion ', lifecyclePhase);
        console.log("lifecyclePhases", lifecyclePhases);
        const phase = lifecyclePhases.find(phase => phase.value === lifecyclePhase);
        console.log("phase", phase);
        return phase ? phase.color : 'rgb(255, 152, 0)';
    };

    const memberList = teams.flatMap(team =>
        team.members?.map(member => ({
            idOwner: member.idOwner,
            firstName: member.firstName,
            lastName: member.lastName
        }))
    );

    // const labelIds = data.map(item => item.hubspot_owner_id);
    // const labels = labelIds.map(idOwner => {
    //     const member = memberList.find(m => m.idOwner === idOwner);
    //     return member ? shortenName(`${member.firstName} ${member.lastName}`) : idOwner;
    // });

    const labels = data?.map(item => {
        const member = memberList.find(m => m.idOwner === item.hubspot_owner_id);
        let mb = member ? shortenName(`${member.firstName} ${member.lastName}`) : item.hubspot_owner_id;

        return `${mb} (${item.total})`
    });

    const visitsData = data.map(item => item.total);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Restaurants Visit\u00E9s',
                data: visitsData,
                backgroundColor: getBackgroundColor(lifecylephase),
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <Bar data={chartData} options={options} />
    )
}

export default ModelBar;