import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { alertActions } from '../redux/actions';
import { TextField, Button, Container, Typography, Alert, Link, Box, CircularProgress } from '@mui/material';
import { UserService } from '../services/users';

const PasswordPage = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state);

    const token = searchParams.get('q');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            dispatch(alertActions.error("Les mots de passe saisis sont différents !"));
            return;
        }

        try {
            await UserService.initPassword(token, password)
                .then(() => {
                    dispatch(alertActions.success("Mot de passe initialisé,"));
                    window.location.href = "/login";
                })
                .catch(error => {
                    console.error('Initialisation de mot de passe échouée :', error);
                    dispatch(alertActions.error("Initialisation de mot de passe échouée !"));
                });
        } catch (error) {
            console.error("Password reset error: ", error);
        } finally {

        }
    };

    return (
        <Box
            sx={{
                backgroundImage: `url('../assets/images/map-background.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
                }}
                slotProps={{
                    input: {
                        readOnly: loading.pending,
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '2rem',
                        left: '2rem',
                    }}
                >
                    <a href='/' title="Page d'accueil"><img src="../assets/images/logowiic-dark.png" alt="Wiicmenu Logo" width="150" /></a>
                </Box>
                <Typography variant="h5" gutterBottom sx={{ mb: 2, textAlign: 'center', color: '#333' }}>
                    Nouveau mot de passe
                </Typography>
                <form onSubmit={handleResetPassword}>
                    <TextField
                        label="Nouveau mot de passe"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        sx={{
                            //backgroundColor: '#fff',
                            borderRadius: 1, mb: 2
                        }}
                        slotProps={{
                            input: {
                                readOnly: loading.pending,
                            },
                        }}
                    />
                    <TextField
                        label="Confirmez le mot de passe"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        sx={{
                            //backgroundColor: '#fff',
                            borderRadius: 1
                        }}
                        slotProps={{
                            input: {
                                readOnly: loading.pending,
                            },
                        }}
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Link href="/login" variant="body2">
                            Se connecter
                        </Link>
                        <Link href="/register" variant="body2">
                            S'inscrire
                        </Link>
                    </Box>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="warning"
                        fullWidth
                        sx={{ mt: 3, height: '48px' }}
                        startIcon={loading.pending && <CircularProgress size={20} color="inherit" />}
                    >
                        {!loading.pending && <span>Réinitialiser</span>}
                    </Button>
                </form>
            </Container>
        </Box>
    );
};

export default PasswordPage;
