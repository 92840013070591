import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement);

const ModelPie = ({ data: cycleStageStats }) => {
  const phases = Array.isArray(cycleStageStats) ? cycleStageStats : [];
  const allTotalsZero = phases.every(phase => phase.total === 0);

  const filteredPhases = allTotalsZero ? [{ label: 'No Data', total: 0.01, color: '#cccccc' }] : phases.filter(phase => phase.total !== 0);

  const filteredLabels = filteredPhases.map(phase => phase.label);
  const filteredTotals = filteredPhases.map(phase => phase.total);
  const backgroundColors = filteredPhases.map(phase => phase.color);

  const data = {
    labels: filteredLabels,
    datasets: [
      {
        label: 'Nombre de Companies par Cycle de Vie',
        data: filteredTotals,
        backgroundColor: backgroundColors,
      }, {

      }
    ],
  };

  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '300px',
        maxHeight: '300px',
        margin: '0 auto',
        marginTop: 15,
        position: 'relative'
      }}>
        <Pie data={data} />
      </div>

      {/* Légende avec 2 colonnes */}
      <div style={{
        marginTop: '10px',
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '16px',
        fontSize: 11
      }}>
        {filteredPhases.map((phase, index) => (
          <div key={phase.label} style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '2px',
            width: '45%',
            justifyContent: index === filteredPhases.length - 1 && filteredPhases.length % 2 !== 0 ? 'flex-start' : 'flex-start',
          }}>
            {/* Couleur associée au cycle de vie */}
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: phase.color,
                marginRight: '10px',
                borderRadius: '50%',
              }}
            ></div>
            {/* Nom du cycle de vie et le nombre */}
            <span>{phase.label}: {phase.total}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default ModelPie;



