import { loaderActionsType } from '../../constants';

const initialState = { pending: false };

export function loading(state = initialState, action) {
  switch (action.type) {
    case loaderActionsType.PENDING:
      return {
        pending: true,
        message: action?.message
      };
    case loaderActionsType.END:
      return {
        pending: false
      };
    default:
      return state
  }
}
