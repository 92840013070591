import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Add, AddCircle, Delete, Edit } from "@mui/icons-material";
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TeamList = ({ teams, onEditTeam, onDeleteTeam, onCreateTeam, onTeamClick, currentUser }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {/* <TableCell>ID</TableCell> */}
                        <TableCell sx={{ textAlign: 'left' }}>
                            <IconButton onClick={() => onCreateTeam()} color="success" disabled={currentUser.profile !== 'SUPERADMIN'}>
                                <AddCircle sx={{ fontSize: 36 }} />
                            </IconButton>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 800 }}>EQUIPE</TableCell>
                        <TableCell sx={{ fontWeight: 800 }}>LEAD</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teams?.map((team, index) => (
                        <TableRow key={team.id}>
                            {/* <TableCell
                                sx={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => onTeamClick(team)}
                            > {team.id}
                            </TableCell> */}
                            <TableCell sx={{ padding: '4px', textAlign: 'left' }}>
                                <IconButton onClick={() => onEditTeam(team)} color="success">
                                    <Edit sx={{ fontSize: 30 }} />
                                </IconButton>
                                <IconButton onClick={() => onDeleteTeam(team)} color="error" disabled={currentUser.profile !== 'SUPERADMIN'}>
                                    <Delete sx={{ fontSize: 30 }} />
                                </IconButton>
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '4px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => onTeamClick(team)}
                            >{team.name}</TableCell>
                            <TableCell>{team?.lead?.firstName} {team?.lead?.lastName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TeamList;
