import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Hidden } from "@mui/material";
import { Edit } from '@mui/icons-material'; // Importer uniquement l'icône Edit, supprimer Delete si non utilisé

const UserList = ({ users, onEditUser }) => { // Retirer onDeleteUser si non utilisé
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell sx={{ fontWeight: 800 }}>Prénom</TableCell>
                        <TableCell sx={{ fontWeight: 800 }}>Nom</TableCell>
                        {/* Masquer ces colonnes en mode mobile */}
                        <Hidden mdDown>
                            <TableCell sx={{ fontWeight: 800 }}>Email</TableCell>
                            <TableCell sx={{ fontWeight: 800 }}>Profil</TableCell>
                            <TableCell sx={{ fontWeight: 800 }}>Équipe</TableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell sx={{ padding: '4px', textAlign: 'left' }}>
                                <IconButton onClick={() => onEditUser(user)} color="success">
                                    <Edit sx={{ fontSize: 30 }} />
                                </IconButton>
                                {/* Bouton Effacer commenté pour être masqué */}
                                {/* 
                                <IconButton onClick={() => onDeleteUser(user)} color="error">
                                    <Delete sx={{ fontSize: 30 }} />
                                </IconButton> 
                                */}
                            </TableCell>
                            <TableCell sx={{ padding: '4px' }}>{user.firstName}</TableCell>
                            <TableCell sx={{ padding: '4px' }}>{user.lastName}</TableCell>
                            {/* Masquer ces colonnes en mode mobile */}
                            <Hidden mdDown>
                                <TableCell sx={{ padding: '4px' }}>{user.email}</TableCell>
                                <TableCell sx={{ padding: '4px' }}>{user.profile}</TableCell>
                                <TableCell sx={{ padding: '4px' }}>{user.team?.name}</TableCell>
                            </Hidden>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserList;
