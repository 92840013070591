import { hubspotActionsType } from '../../constants';

const initialState = {};
export const hubspot = (state = initialState, action) => {
  switch (action.type) {
    case hubspotActionsType.LIST_OWNERS:
      return {
        ...state,
        owners: action.owners
      };

    default:
      return state;
  }
}

