import { commonService } from '../generic.service';
//
const getGlobalStatForDateRange = (teamId, hubspotOwnerId, startDate, endDate) => {
    hubspotOwnerId = (hubspotOwnerId == null || hubspotOwnerId === '') ? 'all' : hubspotOwnerId
    return commonService.get(`Statistic/global?team=${teamId}&hubspotOwnerId=${hubspotOwnerId}&startDate=${startDate}&endDate=${endDate}`);
};

const getMembersCompare = (lifecylephase, teamId, startDate, endDate) => {
    console.log("members ", lifecylephase, teamId);
    return commonService.get(`Statistic/compare-members?teamId=${teamId}&lifecylephase=${lifecylephase}&startDate=${startDate}&endDate=${endDate}`);
};

const getCycleStageStat = (hubspotOwnerId, team, startDate, endDate) => {
    return commonService.get(`Statistic/cyclestage?hubspotOwnerId=${hubspotOwnerId}&team=${team}&startDate=${startDate}&endDate=${endDate}`);
};

export const StatisticService = {
    getGlobalStatForDateRange,
    getMembersCompare,
    getCycleStageStat
};
