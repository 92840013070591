import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField } from '@mui/material';
import { HubspotService } from "../services/companies/hubspot.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { fetchDataCompanyNames } from "../services/companies/poi.service";
import { lifecyclePhases } from "../helpers/utils";
import { hubspotActions } from '../redux/actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Filter = ({
    filters,
    filterOpen,
    handleFilterOpen,
    handleFilterClose,
    handleFilterChange,
    isFilterActive,
    handleFilterApply,
    handleFilterClear
}) => {
    const dispatch = useDispatch();
    const [companiesOwners, setCompaniesOwners] = useState([]);
    const [companiesNames, setCompaniesNames] = useState([]);
    const [companyNameToSearch, setCompanyNameToSearch] = useState('');
    const [selectedCompanyNames, setSelectedCompanyNames] = useState(filters.companyNames || []);
    const [selectedCompanyOwners, setSelectedCompanyOwners] = useState(filters.companyOwners || []);
    const [selectedCycles, setSelectedCycles] = useState(filters.cycles || []);

    //OPTIM
    const { hubspot } = useSelector(state => state);
    useEffect(() => {
        if (!hubspot.owners || hubspot.owners.length === 0)
            dispatch(hubspotActions.getOwners());
    }, []);

    useEffect(() => {
        const filteredOwners = hubspot.owners?.filter(owner =>
            owner.firstName.trim() || owner.lastName.trim()
        );
        setCompaniesOwners(filteredOwners);
    }, [hubspot.owners]);

    // const fetchOwners = useCallback(async () => {
    //     try {
    //         const { results } = await HubspotService.get('hubspot/owners');
    //         const filteredOwners = results.filter(owner =>
    //             owner.firstName.trim() || owner.lastName.trim()
    //         );
    //         setCompaniesOwners(filteredOwners);
    //     } catch (err) {
    //         console.error('Erreur lors de la récupération des propriétaires', err);
    //     }
    // }, []);

    // useEffect(() => {
    //     fetchOwners();
    // }, [fetchOwners]);

    useEffect(() => {
        if (companyNameToSearch.length >= 3) {
            fetchDataCompanyNames(companyNameToSearch)
                .then(setCompaniesNames)
                .catch((error) => {
                    console.error('Erreur lors de la récupération des noms d\'entreprises:', error);
                });
        } else {
            setCompaniesNames([]);
        }
    }, [companyNameToSearch]);

    const handleCompanyNamesChange = (event, newValue) => {
        setSelectedCompanyNames(newValue);
        handleFilterChange({ target: { name: 'companyNames', value: newValue } });
    };

    const handleCompanyOwnersChange = (event, newValue) => {
        setSelectedCompanyOwners(newValue);
        handleFilterChange({ target: { name: 'companyOwners', value: newValue } });
    };

    const handleCyclesChange = (event, newValue) => {
        setSelectedCycles(newValue);
        handleFilterChange({ target: { name: 'cycles', value: newValue } });
    };

    const handleClearFilters = () => {
        setSelectedCompanyNames([]);
        setSelectedCompanyOwners([]);
        handleFilterClear();
    };

    return (
        <div>
            <Box
                style={{
                    backgroundColor: "transparent",
                    zIndex: 10,
                    marginRight: 8,
                    float: 'right',
                    right: 0,
                    marginTop: '9rem',
                    padding: 0,
                    minWidth: 32,
                    width: 32,
                    position: 'absolute'
                }}
            >
                <Button
                    onClick={handleFilterOpen}
                    sx={{
                        backgroundColor: "white",
                        marginTop: 0,
                        padding: 0,
                        minWidth: 32,
                        width: 32,
                        height: 32,
                        border: '2px solid #d2caca',
                        borderRadius: '6px'
                    }}
                >
                    <FontAwesomeIcon icon={faFilter} style={{ color: isFilterActive ? "red" : "black" }} />
                </Button>

                {/* <div
                    style={{
                        backgroundColor: "white",
                        marginTop: 14,
                        padding: 0,
                        minWidth: 32,
                        width: 32,
                        height: 32,
                        border: '2px solid #d2caca',
                        borderRadius: '6px'
                    }}
                >
                    <IconButton
                        color="secondary"
                        onClick={backToHome}
                        aria-label="logout"
                        title="Revenir à la page d'accueil"
                        sx={{ padding: '2px !important' }}
                    >
                        <ArrowBack style={{ color: "black", padding: 2 }} />
                    </IconButton>
                </div> */}
            </Box>

            <Dialog open={filterOpen} onClose={handleFilterClose} maxWidth="xs" fullWidth>
                <DialogTitle align='center'>
                    <strong>Filtrer par critères</strong>
                    <IconButton
                        aria-label="close"
                        onClick={handleFilterClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                        <Autocomplete
                            multiple
                            id="phase-filter-label"
                            options={lifecyclePhases}
                            noOptionsText="Aucune option disponible"
                            disableCloseOnSelect
                            value={selectedCycles}
                            getOptionLabel={(option) => option?.label || ""}
                            onChange={handleCyclesChange}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Phase du cycle de Vie"
                                    placeholder="Sélectionner des cycles"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { height: '54px' }
                                    }}
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                        <Autocomplete
                            multiple
                            id="company_name"
                            options={companiesNames}
                            noOptionsText="Aucune option disponible"
                            disableCloseOnSelect
                            value={selectedCompanyNames}
                            getOptionLabel={(option) => option?.name || ""}
                            onChange={handleCompanyNamesChange}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Noms des entreprises"
                                    placeholder="Sélectionner des noms"
                                    onChange={(e) => setCompanyNameToSearch(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { height: '54px' }
                                    }}
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                        <Autocomplete
                            multiple
                            id="company_owners"
                            options={companiesOwners}
                            noOptionsText="Aucune option disponible"
                            disableCloseOnSelect
                            value={selectedCompanyOwners}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            onChange={handleCompanyOwnersChange}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {`${option.firstName} ${option.lastName}`}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Propriétaires des entreprises"
                                    placeholder="Sélectionner des propriétaires"
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { height: '54px' }
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
                    <Button onClick={handleClearFilters} color="warning" variant="outlined" style={{ width: 150 }}>
                        Effacer
                    </Button>
                    <Button onClick={handleFilterApply} color="primary" variant="outlined" style={{ width: 150 }}>
                        Appliquer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Filter;
