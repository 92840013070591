import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement);

const ModelBar2 = () => {
    const data = {
        labels: ['Membre A', 'Membre B', 'Membre C', 'Membre D'],
        datasets: [
            {
                label: 'Installations',
                data: [10, 15, 12, 8],
                backgroundColor: '#3f51b5',
            },
            {
                label: 'Abonnements Basique',
                data: [5, 7, 6, 4],
                backgroundColor: '#ff9800',
            },
            {
                label: 'Abonnements Premium',
                data: [2, 5, 3, 4],
                backgroundColor: '#f50057',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <>
            <Bar data={data} options={options} />
        </>
    )
}

export default ModelBar2;