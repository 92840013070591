import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Container, Typography, Alert, Link, Box, CircularProgress } from '@mui/material';
import { UserService } from '../services/users';
import { alertActions } from '../redux/actions';

const ResetpasswordPage = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const { loading } = useSelector(state => state);

    const handleReset = async (e) => {
        e.preventDefault();
        UserService.resetPassword(email)
            .then(() => dispatch(alertActions.success("Vous devez avoir reçu un email.")))
            .catch(error => {
                console.error('Réinitialisation de mot de passe échoué :', error);
                dispatch(alertActions.error(error.response?.data?.message || "Réinitialisation de mot de passe échouée !"));
            });
    };

    return (
        <Box
            sx={{
                backgroundImage: `url('../assets/images/map-background.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '2rem',
                        left: '2rem',
                    }}
                >
                    <a href='/' title="Page d'accueil"><img src="../assets/images/logowiic-dark.png" alt="Wiicmenu Logo" width="150" /></a>
                </Box>
                <Typography variant="h5" gutterBottom sx={{ mb: 2, textAlign: 'center', color: '#333' }}>
                    Réinitialiser le mot de passe
                </Typography>

                <form onSubmit={handleReset}>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        sx={{
                            //backgroundColor: '#fff',
                            borderRadius: 1, mb: 2
                        }}
                        slotProps={{
                            input: {
                                readOnly: loading.pending,
                            },
                        }}
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Link href="/login" variant="body2">
                            Se connecter
                        </Link>
                    </Box>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="success"
                        fullWidth
                        sx={{ mt: 3, height: '48px' }}
                        startIcon={loading.pending && <CircularProgress size={20} color="inherit" />}
                    >
                        {!loading.pending && <span>Envoyer</span>}
                    </Button>
                </form>
            </Container>
        </Box>
    );
};

export default ResetpasswordPage;
