import { authActionsType, TOKEN_USER, CURRENT_USER } from '../../constants';
import { commonService } from '../../services';
import { alertActions } from '../actions';

const login = (email = null, password = null) => {
    return dispatch => {
        commonService.post('auth/login', { email, password })
            .then(
                response => {
                    if (response) {
                        dispatch(success(response));
                    } else {
                        dispatch(alertActions.error(response.message));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.response?.data?.message || "Authentification échouée !"));
                });
    };
    function success(res) {
        localStorage.setItem(TOKEN_USER, res.token);
        localStorage.setItem(CURRENT_USER, JSON.stringify(res));
        return { type: authActionsType.LOGIN_SUCCESS, user: res }
    }
}

const logout = () => {
    return dispatch => {
        localStorage.clear();
        dispatch(signout());
    }
    function signout() {
        return { type: authActionsType.LOGOUT }
    }
}

export const authActions = {
    login,
    logout
};